import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Only renders children if the user is NOT logged in.
 */
const RequirePublicOnly = ({ children, userAuthContext }) => {
  const location = useLocation();

  const locationMatchesUrl = location.pathname === window.location.pathname;
  const shouldRedirectToLandingPage =
    locationMatchesUrl &&
    userAuthContext?.isAuthenticated &&
    userAuthContext?.user.isCityUser;

  if (shouldRedirectToLandingPage) {
    const redirectDestination = {
      pathname: '/cognitive',
      search: location?.search || '',
    };
    return <Navigate to={redirectDestination} />;
  }

  return children;
};

RequirePublicOnly.propTypes = {
  children: PropTypes.node,
};

export default RequirePublicOnly;
