import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { WindowSizeProvider } from '~/services/hooks/windowSize';
import { createUnauthorizedHandler } from '~/services/hooks/useUserAuth';
import CityContextProvider from '~/components/CityContextProvider';
import ThemeCssStyles from '~/components/ThemeCssStyles';
import { AuthPanelContainer } from '~/components/AuthPanel';
import RequirePublicOnly from '~/components/RequirePublicOnly';
import { LoaderFunction } from '@remix-run/node';
import { getUserSession } from '~/utils/auth.server';
import { useLoaderData, useRouteLoaderData } from '@remix-run/react';
import { isJwtExpired } from '~/utils/web';
import { UserAuthContext } from '~/types';

interface LoaderData {
  token: string;
}

export const loader: LoaderFunction = async ({ request }) => {
  const user = await getUserSession(request);
  if (user?.access_token && !isJwtExpired(user?.access_token)) {
    return {
      token: user.access_token,
    };
  }
  return {
    token: null,
  };
};

export default function Auth(): JSX.Element {
  const rootLoaderData = useRouteLoaderData('root') as {
    userAuthContext: UserAuthContext;
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useLoaderData<LoaderData>();

  return (
    <WindowSizeProvider>
      <CityContextProvider
        location={location}
        unauthorizedHandler={createUnauthorizedHandler({
          navigate,
        })}
        token={token}
      >
        <>
          <ThemeCssStyles cityColors={null} />
          <RequirePublicOnly userAuthContext={rootLoaderData.userAuthContext}>
            <AuthPanelContainer showPrivacyBanner>
              <Outlet />
            </AuthPanelContainer>
          </RequirePublicOnly>
        </>
      </CityContextProvider>
    </WindowSizeProvider>
  );
}
